// get subscription user
export const GET_SUBSCRIPTION_USER_SUCCESS = "GET_SUBSCRIPTION_USER_SUCCESS";
export const GET_SUBSCRIPTION_USER_FAILED = "GET_SUBSCRIPTION_USER_FAILED";
export const IS_LOADING_GET_SUBSCRIPTION_USER =
  "IS_LOADING_GET_SUBSCRIPTION_USER";

// create subscription user
export const CREATE_SUBSCRIPTION_USER_SUCCESS =
  "CREATE_SUBSCRIPTION_USER_SUCCESS";
export const CREATE_SUBSCRIPTION_USER_FAILED =
  "CREATE_SUBSCRIPTION_USER_FAILED";
export const IS_LOADING_CREATE_SUBSCRIPTION_USER =
  "IS_LOADING_GET_SUBSCRIPTION_USER";

// get subscription
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILED = "GET_SUBSCRIPTION_FAILED";
export const IS_LOADING_GET_SUBSCRIPTION = "IS_LOADING_GET_SUBSCRIPTION";

// create subscription
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILED = "CREATE_SUBSCRIPTION_FAILED";
export const IS_LOADING_CREATE_SUBSCRIPTION = "IS_LOADING_CREATE_SUBSCRIPTION";

// update subscription
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAILED = "UPDATE_SUBSCRIPTION_FAILED";
export const IS_LOADING_UPDATE_SUBSCRIPTION = "IS_LOADING_UPDATE_SUBSCRIPTION";

// payment - make hash
export const MAKE_HASH_SUCCESS = "MAKE_HASH_SUCCESS";
export const MAKE_HASH_FAILED = "MAKE_HASH_FAILED";
export const IS_LOADING_MAKE_HASH = "IS_LOADING_MAKE_HASH";

// payment - one time
export const ONE_TIME_PAYMENT_SUCCESS = "ONE_TIME_PAYMENT_SUCCESS";
export const ONE_TIME_PAYMENT_FAILED = "ONE_TIME_PAYMENT_FAILED";
export const IS_LOADING_ONE_TIME_PAYMENT = "IS_LOADING_ONE_TIME_PAYMENT";

// payment - subscription
export const SUBSCRIPTION_PAYMENT_SUCCESS = "SUBSCRIPTION_PAYMENT_SUCCESS";
export const SUBSCRIPTION_PAYMENT_FAILED = "SUBSCRIPTION_PAYMENT_FAILED";
export const IS_LOADING_SUBSCRIPTION_PAYMENT =
  "IS_LOADING_SUBSCRIPTION_PAYMENT";

// get all subscription plans
export const GET_ALL_SUBSCRIPTION_PLANS_SUCCESS =
  "GET_ALL_SUBSCRIPTION_PLANS_SUCCESS";
export const GET_ALL_SUBSCRIPTION_PLANS_FAILED =
  "GET_ALL_SUBSCRIPTION_PLANS_FAILED";
export const IS_LOADING_GET_ALL_SUBSCRIPTION_PLANS =
  "IS_LOADING_GET_ALL_SUBSCRIPTION_PLANS";

// create new payment history
export const CREATE_NEW_PAYMENT_HISTORY_SUCCESS =
  "CREATE_NEW_PAYMENT_HISTORY_SUCCESS";
export const CREATE_NEW_PAYMENT_HISTORY_FAILED =
  "CREATE_NEW_PAYMENT_HISTORY_FAILED";
export const IS_LOADING_CREATE_NEW_PAYMENT_HISTORY =
  "IS_LOADING_CREATE_NEW_PAYMENT_HISTORY";

// get all payment history of company
export const GET_ALL_PAYMENT_HISTORY_OF_COMPANY_SUCCESS =
  "GET_ALL_PAYMENT_HISTORY_OF_COMPANY_SUCCESS";
export const GET_ALL_PAYMENT_HISTORY_OF_COMPANY_FAILED =
  "GET_ALL_PAYMENT_HISTORY_OF_COMPANY_FAILED";
export const IS_LOADING_GET_ALL_PAYMENT_HISTORY_OF_COMPANY =
  "IS_LOADING_GET_ALL_PAYMENT_HISTORY_OF_COMPANY";

// update subscription when free plan to premium plan
export const UPDATE_SUBSCRIPTION_TO_ANOTHER_SUCCESS =
  "UPDATE_SUBSCRIPTION_TO_ANOTHER_SUCCESS";
export const UPDATE_SUBSCRIPTION_TO_ANOTHER_FAILED =
  "UPDATE_SUBSCRIPTION_TO_ANOTHER_FAILED";
export const IS_LOADING_UPDATE_SUBSCRIPTION_TO_ANOTHER =
  "IS_LOADING_UPDATE_SUBSCRIPTION_TO_PREMIUM";
