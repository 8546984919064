import React from "react";
import "./index.css";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="footer-container">
        <div className="footer-row">
          <div className="footer-col-8">
            <h6>About</h6>
            <p className="text-justify">
              At <b> Epinics (PVT) LTD</b>, we specialize in providing
              comprehensive ERP solutions tailored to meet the diverse needs of
              businesses across all industries. Our mission is to empower
              companies with the tools they need to streamline their operations,
              enhance productivity, and achieve their business goals
              efficiently. With a robust suite of integrated applications, we
              offer end-to-end solutions that cover everything from finance and
              human resources to supply chain management and customer relations.
              Our expert team is dedicated to delivering innovative, scalable,
              and user-friendly ERP systems that evolve with your business,
              ensuring you stay ahead in a competitive market. Trust{" "}
              <b> Epinics (PVT) LTD </b> to be your partner in driving success
              and growth with cutting-edge ERP technology.
            </p>
          </div>

          <div className="footer-col-3">
            <h6>Quick Links</h6>
            <ul className="footer-links">
              <li>
                <h8 href="http://scanfcode.com/about/">About Us</h8>
              </li>
              <li>
                <h8 href="http://scanfcode.com/contact/">Contact Us</h8>
              </li>
              <li>
                <h8 href="http://scanfcode.com/contribute-at-scanfcode/">
                  Contribute
                </h8>
              </li>
              <li>
                <h8 href="http://scanfcode.com/privacy-policy/">
                  Privacy Policy
                </h8>
              </li>
              <li>
                <h8 href="http://scanfcode.com/sitemap/">Sitemap</h8>
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ marginTop: "10px" }} />
      </div>
      <div className="footer-container">
        <div className="footer-row">
          <div className="footer-col-8">
            Copyright &copy; 2024 All Rights Reserved by Epinics (Pvt) Ltd.
          </div>

          <div className="footer-col-3">
            <ul className="social-icons">
              <li>
                <FacebookRoundedIcon sx={{ fontSize: "40px" }} />
              </li>
              <li>
                <InstagramIcon sx={{ fontSize: "40px" }} />
              </li>
              <li>
                <LinkedInIcon sx={{ fontSize: "40px" }} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
