import Cookies from "js-cookie";

import {
  AUTHENTICATE_SUCCESS,
  SET_IS_LOADING,
  AUTHENTICATE_FAILED,
  SET_BRANCH_ID,
  LOGOUT,
} from "../actions/types";

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  token: null,
  isFailed: false,
  errorMessage: null,
  companyId: null,
  branchList: [],
  branchId: null,
  companyId: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case AUTHENTICATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        token: payload.token,
        companyId: payload.companyId,
        branchList: payload.branch,
        branchId: payload.branch[0].id,
        isFailed: false,
        errorMessage: null,
        companyId: payload.branch[0].companyId,
      };
    case AUTHENTICATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        errorMessage: payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_BRANCH_ID:
      return {
        ...state,
        branchId: payload,
      };
    case LOGOUT:
      localStorage.clear();
      sessionStorage.clear();
      Cookies.remove("token_");
      return {
        isLoading: false,
        isAuthenticated: false,
        token: null,
        isFailed: false,
        errorMessage: null,
        branchList: [],
        branchId: null,
      };

    default:
      return state;
  }
}
