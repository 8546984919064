import {
  GET_STATS_DETAILS_SUCCESS,
  GET_STATS_DETAILS__FAILED,
  IS_LOADING_GET_STATS_DETAILS,
} from "../actions/types";

const initialState = {
  // - get stats - //
  isGetStatsDetailsLoading: false,
  isGetStatsDetailsSuccess: false,
  statsDetails: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_STATS_DETAILS_SUCCESS:
      return {
        ...state,
        isGetStatsDetailsSuccess: true,
        statsDetails: payload,
      };

    case GET_STATS_DETAILS__FAILED:
      return {
        ...state,
        isGetStatsDetailsSuccess: false,
        statsDetails: null,
      };

    case IS_LOADING_GET_STATS_DETAILS:
      return {
        ...state,
        isGetStatsDetailsLoading: payload,
      };

    default:
      return state;
  }
}
