import "./index.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logoImage from "../../../layout/assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions";
import { showAlert } from "../../../../common/actions";
import setHeaders from "../../../dashboard/utils/CommonHeaders";
import Cookies from "js-cookie";
import PersonIcon from "@mui/icons-material/Person";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import Loading from "../../../../common/components/Loading";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = ({
  auth: { isLoading, isAuthenticated, isFailed, errorMessage, token },
  login,
  showAlert,
  setIsLoading,
}) => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    if (!isLoading) {
      if (!username) {
        showAlert({
          isAlertOpen: true,
          alertType: "danger",
          alertMessage: "Please Enter a Valid Email!",
        });
      } else if (!password) {
        showAlert({
          isAlertOpen: true,
          alertType: "danger",
          alertMessage: "Please Enter a Valid Password!",
        });
      } else {
        login(username, password);
        setIsLoading(!isLoading);
      }
    }
  };

  const handleKeyDown = (ev) => {
    if (ev.keyCode == 13) {
      handleLogin();
    }
  };

  useEffect(() => {
    if (!isLoading) {
      // navigate("/");

      if (isAuthenticated) {
        Cookies.set("token_", token, { path: "" });
        setHeaders(token);
        showAlert({
          isAlertOpen: true,
          alertType: "success",
          alertMessage: "Successfully Logged In!",
        });
        navigate("/");
      } else if (isFailed) {
        showAlert({
          isAlertOpen: true,
          alertType: "danger",
          alertMessage: errorMessage || "Login Failed!",
        });
      }
    }
    setIsLoading(isLoading);
  }, [isLoading]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <div className="login-card" onKeyDown={handleKeyDown}>
      <div className="login-header">
        <img src={logoImage} alt="Logo" />
      </div>
      <div className="login-form">
        <div className="username-field">
          <TextField
            autoFocus
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#000",
                fontWeight: "500",
                // Class for the border around the input field
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "2.5px",
                },
              },
              // Class for the label of the input field
              "& .MuiInputLabel-outlined": {
                fontWeight: "bold",
              },
            }}
            id="emailOrPhone"
            name="emailOrPhone"
            onInput={(e) => setUsername(e.target.value)}
            label="Email or Phone"
            variant="outlined"
            color="success"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="password-field">
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#000",
                fontWeight: "500",
                // Class for the border around the input field
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "2.5px",
                },
              },
              // Class for the label of the input field
              "& .MuiInputLabel-outlined": {
                fontWeight: "bold",
              },
            }}
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            onInput={(e) => setPassword(e.target.value)}
            label="Password"
            variant="outlined"
            color="success"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="login-button">
          <Button
            variant="contained"
            fullWidth
            onClick={handleLogin}
            color="success"
            type="button"
          >
            Sign In
          </Button>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authState,
});

export default connect(mapStateToProps, {
  login,
  showAlert,
})(Login);
