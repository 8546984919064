import {
  GET_SUBSCRIPTION_USER_SUCCESS,
  GET_SUBSCRIPTION_USER_FAILED,
  IS_LOADING_GET_SUBSCRIPTION_USER,
  CREATE_SUBSCRIPTION_USER_SUCCESS,
  CREATE_SUBSCRIPTION_USER_FAILED,
  IS_LOADING_CREATE_SUBSCRIPTION_USER,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAILED,
  IS_LOADING_GET_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILED,
  IS_LOADING_CREATE_SUBSCRIPTION,
  IS_LOADING_MAKE_HASH,
  MAKE_HASH_SUCCESS,
  MAKE_HASH_FAILED,
  ONE_TIME_PAYMENT_SUCCESS,
  ONE_TIME_PAYMENT_FAILED,
  IS_LOADING_ONE_TIME_PAYMENT,
  SUBSCRIPTION_PAYMENT_SUCCESS,
  SUBSCRIPTION_PAYMENT_FAILED,
  IS_LOADING_SUBSCRIPTION_PAYMENT,
  GET_ALL_SUBSCRIPTION_PLANS_SUCCESS,
  GET_ALL_SUBSCRIPTION_PLANS_FAILED,
  IS_LOADING_GET_ALL_SUBSCRIPTION_PLANS,
  CREATE_NEW_PAYMENT_HISTORY_SUCCESS,
  CREATE_NEW_PAYMENT_HISTORY_FAILED,
  IS_LOADING_CREATE_NEW_PAYMENT_HISTORY,
  GET_ALL_PAYMENT_HISTORY_OF_COMPANY_SUCCESS,
  GET_ALL_PAYMENT_HISTORY_OF_COMPANY_FAILED,
  IS_LOADING_GET_ALL_PAYMENT_HISTORY_OF_COMPANY,
  UPDATE_SUBSCRIPTION_TO_ANOTHER_SUCCESS,
  UPDATE_SUBSCRIPTION_TO_ANOTHER_FAILED,
  IS_LOADING_UPDATE_SUBSCRIPTION_TO_ANOTHER,
} from "../actions/types";

const initialState = {
  // - get subscription user - //
  isSubscriptionUserDetailsLoading: false,
  isSubscriptionUserDetailsSuccess: false,
  subscriptionUserId: null,
  subscriptionUserCompanyId: null,
  subscriptionUserActiveStatus: null,
  subscriptionUserFirstName: null,
  subscriptionUserLastName: null,
  subscriptionUserEmail: null,
  subscriptionUserAddress: null,
  subscriptionUserCity: null,
  subscriptionUserPhone: null,

  // - create subscription user - //
  isCreateSubscriptionUserLoading: false,
  isCreateSubscriptionUserSuccess: false,

  // - get subscription - //
  isSubscriptionDetailsLoading: false,
  isSubscriptionDetailsSuccess: false,
  subscriptionId: null,
  subscriptionCompanyId: null,
  subscriptionActiveStatus: null,
  subscriptionIsRecurring: null,
  subscriptionCurrentPeriodStartAt: null,
  subscriptionCurrentPeriodEndAt: null,
  subscriptionPlanName: null,
  subscriptionPlanAmount: null,
  subscriptionPlanDescription: null,
  subscriptionPlanValueAddedPrice: null,
  subscriptionPlanServiceName: null,
  subascriptionCardHolderName: null,
  subscriptionCardNumber: null,
  subscriptionCardType: null,
  subscriptionCardExpiry: null,

  // - create subscription - //
  isCreateSubscriptionLoading: false,
  isCreateSubscriptionSuccess: false,

  // - make hash - //
  isMakeHashLoading: false,
  isMakeHashSuccess: false,
  hash: null,

  // - one time payment - //
  isOneTimePaymentLoading: false,
  isOneTimePaymentSuccess: false,

  // - subscription payment - //
  isSubscriptionPaymentLoading: false,
  isSubscriptionPaymentSuccess: false,

  // - get all subscription plans - //
  isGetAllSubscriptionPlansLoading: false,
  isGetAllSubscriptionPlansSuccess: false,
  subscriptionPlans: null,

  // - create new payment history - //
  isCreateNewPaymentHistoryLoading: false,
  isCreateNewPaymentHistorySuccess: false,

  // - get all payment history of company - //
  isGetAllPaymentHistoryOfCompanyLoading: false,
  isGetAllPaymentHistoryOfCompanySuccess: false,
  paymentHistory: [
    {
      id: 1,
      userId: 1,
      subscriptionId: 1,
      orderId: "ItemNo123456",
      paymentId: 101112,
      companyId: 1,
      service: "POS",
      status: "SUCCESS",
      amount: 1000,
      createdAt: "2024-11-16T16:27:41.000Z",
      updatedAt: "2024-11-16T17:16:54.000Z",
    },
  ],

  // - update subscription to another - //
  isUpdateSubscriptionToAnotherLoading: false,
  isUpdateSubscriptionToAnotherSuccess: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    // get subscription user
    case GET_SUBSCRIPTION_USER_SUCCESS:
      return {
        ...state,
        isSubscriptionUserDetailsSuccess: true,
        subscriptionUserId: payload.id,
        subscriptionUserCompanyId: payload.companyId,
        subscriptionUserActiveStatus: payload.status,
        subscriptionUserFirstName: payload.firstName,
        subscriptionUserLastName: payload.lastName,
        subscriptionUserEmail: payload.email,
        subscriptionUserAddress: payload.address,
        subscriptionUserCity: payload.city,
        subscriptionUserPhone: "0771199993",
      };

    case GET_SUBSCRIPTION_USER_FAILED:
      return {
        ...state,
        isSubscriptionUserDetailsSuccess: false,
        subscriptionUserId: null,
        subscriptionUserCompanyId: null,
        subscriptionUserActiveStatus: null,
        subscriptionUserFirstName: null,
        subscriptionUserLastName: null,
        subscriptionUserEmail: null,
        subscriptionUserAddress: null,
        subscriptionUserCity: null,
        subscriptionUserPhone: null,
      };

    case IS_LOADING_GET_SUBSCRIPTION_USER:
      return {
        ...state,
        isSubscriptionUserDetailsLoading: payload,
      };

    // create subscription user
    case CREATE_SUBSCRIPTION_USER_SUCCESS:
      return {
        ...state,
        isCreateSubscriptionUserSuccess: true,
        subscriptionUserId: payload.id,
        subscriptionUserCompanyId: payload.companyId,
        subscriptionUserActiveStatus: payload.status,
        subscriptionUserFirstName: payload.firstName,
        subscriptionUserLastName: payload.lastName,
        subscriptionUserEmail: payload.email,
        subscriptionUserAddress: payload.address,
        subscriptionUserCity: payload.city,
        subscriptionUserPhone: "0771199993",
      };

    case CREATE_SUBSCRIPTION_USER_FAILED:
      return {
        ...state,
        isCreateSubscriptionUserSuccess: false,
        subscriptionUserId: null,
        subscriptionUserCompanyId: null,
        subscriptionUserActiveStatus: null,
        subscriptionUserFirstName: null,
        subscriptionUserLastName: null,
        subscriptionUserEmail: null,
        subscriptionUserAddress: null,
        subscriptionUserCity: null,
        subscriptionUserPhone: null,
      };

    case IS_LOADING_CREATE_SUBSCRIPTION_USER:
      return {
        ...state,
        isSubscriptionUserDetailsLoading: payload,
      };

    // get subscription
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isSubscriptionDetailsSuccess: true,
        subscriptionId: payload.id,
        subscriptionCompanyId: payload.companyId,
        subscriptionActiveStatus: payload.activeStatus,
        subscriptionIsRecurring: payload.isRecurring,
        subscriptionCurrentPeriodStartAt: payload.currentPeriodStartAt,
        subscriptionCurrentPeriodEndAt: payload.currentPeriodEndAt,
        subscriptionPlanName: payload.plan.name,
        subscriptionPlanAmount: payload.plan.amount,
        subscriptionPlanDescription: payload.plan.description,
        subscriptionPlanValueAddedPrice: payload.plan.valueAddedPrice,
        subscriptionPlanServiceName: payload.serviceName,
        subascriptionCardHolderName: payload.card.cardHolderName,
        subscriptionCardNumber: payload.card.cardNumber,
        subscriptionCardType: payload.card.cardType,
        subscriptionCardExpiry: payload.card.cardExpire,
      };

    case GET_SUBSCRIPTION_FAILED:
      return {
        ...state,
        isSubscriptionDetailsSuccess: false,
        subscriptionId: null,
        subscriptionCompanyId: null,
        subscriptionActiveStatus: null,
        subscriptionIsRecurring: null,
        subscriptionCurrentPeriodStartAt: null,
        subscriptionCurrentPeriodEndAt: null,
        subscriptionPlanName: null,
        subscriptionPlanAmount: null,
        subscriptionPlanDescription: null,
        subscriptionPlanValueAddedPrice: null,
        subscriptionPlanServiceName: null,
        subascriptionCardHolderName: null,
        subscriptionCardNumber: null,
        subscriptionCardType: null,
        subscriptionCardExpiry: null,
      };

    case IS_LOADING_GET_SUBSCRIPTION:
      return {
        ...state,
        isSubscriptionDetailsLoading: payload,
      };

    // make hash
    case MAKE_HASH_SUCCESS:
      return {
        ...state,
        isMakeHashSuccess: true,
        hash: payload,
      };

    case MAKE_HASH_FAILED:
      return {
        ...state,
        isMakeHashSuccess: false,
        hash: null,
      };

    case IS_LOADING_MAKE_HASH:
      return {
        ...state,
        isMakeHashLoading: payload,
      };

    // get all subscription plans
    case GET_ALL_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        isGetAllSubscriptionPlansSuccess: true,
        subscriptionPlans: payload,
      };

    case GET_ALL_SUBSCRIPTION_PLANS_FAILED:
      return {
        ...state,
        isGetAllSubscriptionPlansSuccess: false,
        subscriptionPlans: null,
      };

    case IS_LOADING_GET_ALL_SUBSCRIPTION_PLANS:
      return {
        ...state,
        isGetAllSubscriptionPlansLoading: payload,
      };

    // create new payment history
    case CREATE_NEW_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        isCreateNewPaymentHistorySuccess: true,
      };

    case CREATE_NEW_PAYMENT_HISTORY_FAILED:
      return {
        ...state,
        isCreateNewPaymentHistorySuccess: false,
      };

    case IS_LOADING_CREATE_NEW_PAYMENT_HISTORY:
      return {
        ...state,
        isCreateNewPaymentHistoryLoading: payload,
      };

    // get all payment history of company
    case GET_ALL_PAYMENT_HISTORY_OF_COMPANY_SUCCESS:
      return {
        ...state,
        isGetAllPaymentHistoryOfCompanySuccess: true,
        paymentHistory: payload,
      };

    case GET_ALL_PAYMENT_HISTORY_OF_COMPANY_FAILED:
      return {
        ...state,
        isGetAllPaymentHistoryOfCompanySuccess: false,
        paymentHistory: null,
      };

    case IS_LOADING_GET_ALL_PAYMENT_HISTORY_OF_COMPANY:
      return {
        ...state,
        isGetAllPaymentHistoryOfCompanyLoading: payload,
      };

    // update subscription to another
    case UPDATE_SUBSCRIPTION_TO_ANOTHER_SUCCESS:
      return {
        ...state,
        isUpdateSubscriptionToAnotherSuccess: true,
      };

    case UPDATE_SUBSCRIPTION_TO_ANOTHER_FAILED:
      return {
        ...state,
        isUpdateSubscriptionToAnotherSuccess: false,
      };

    case IS_LOADING_UPDATE_SUBSCRIPTION_TO_ANOTHER:
      return {
        ...state,
        isUpdateSubscriptionToAnotherLoading: payload,
      };

    default:
      return state;
  }
}
