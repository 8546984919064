import React, { useEffect } from "react";
import styles from "./index.module.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getStatsDetails } from "../../actions";
import { useNavigate, useLocation } from "react-router-dom";
import { FaRegFaceSadTear } from "react-icons/fa6";

const CurrentStockDetailsSection = ({
  getStatsDetails,
  stats: { statsDetails, isGetStatsDetailsLoading, isGetStatsDetailsSuccess },
  auth: { branchList, branchId, companyId },
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const defaultDetails = {
    valueOfCurrentStock: 0,
    totalExpectedSales: 0,
    totalExpectedProfit: 0,
    outOfStockItemCount: 0,
  };

  const {
    valueOfCurrentStock,
    totalExpectedSales,
    totalExpectedProfit,
    outOfStockItemCount,
  } = statsDetails || defaultDetails;

  const boxesDetails = [
    {
      id: 1,
      title: "Value of Current Stocks",
      value: valueOfCurrentStock,
    },
    {
      id: 2,
      title: "Total Expected Sales",
      value: totalExpectedSales,
    },
    {
      id: 3,
      title: "Total Expected Profit",
      value: totalExpectedProfit,
    },
    {
      id: 4,
      title: "Out of Stock Items",
      value: outOfStockItemCount,
    },
  ];

  useEffect(() => {
    if (branchId && companyId) {
      getStatsDetails(companyId, branchId);
    }
  }, [branchId, companyId, location]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>Current Stock Details</div>

      {isGetStatsDetailsLoading ? (
        <div className={styles.loading}>
          <div className={styles.spinner}></div>
        </div>
      ) : isGetStatsDetailsSuccess && statsDetails ? (
        <div className={styles.boxesDiv}>
          {boxesDetails.map((item) => (
            <div key={item.id} className={styles.box}>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.value}>{item.value}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.noData}>
          <FaRegFaceSadTear className={styles.icon} />
          <p>No stock data available at the moment.</p>
        </div>
      )}
    </div>
  );
};

CurrentStockDetailsSection.propTypes = {
  getStatsDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authState,
  stats: state.statsState,
});

export default connect(mapStateToProps, {
  getStatsDetails,
})(CurrentStockDetailsSection);
