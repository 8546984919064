import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";
import dashboard from "./modules/dashboard/reducers/index";
import authState from "./modules/auth/reducers/index";
import commonState from "./common/reducers/index";
import paymentState from "./modules/payment/reducers/index";
import statsState from "./modules/stats/reducers/index";
import supplierInvoiceState from "./modules/invoices/reducers/index";

const intialState = {};
const middleWare = [thunk];
export const store = configureStore({
  reducer: {
    dashboard,
    authState,
    commonState,
    paymentState,
    statsState,
    supplierInvoiceState,
  },
});
export default store;
