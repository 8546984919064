import axios from "axios";
import {
  GET_STATS_DETAILS_SUCCESS,
  GET_STATS_DETAILS__FAILED,
  IS_LOADING_GET_STATS_DETAILS,
} from "./types";

// environment variables
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Get stock details
export const getStatsDetails = (companyId, branchId) => async (dispatch) => {
  try {
    dispatch({ type: IS_LOADING_GET_STATS_DETAILS, payload: true });

    const res = await axios.get(
      `${BASE_URL}api/dashboard/inventory/stock-stats`,
      {
        params: {
          companyId: companyId,
          branchId: branchId,
        },
      }
    );

    if (res.data.success && res.data.data) {
      dispatch({
        type: GET_STATS_DETAILS_SUCCESS,
        payload: res.data.data,
      });
    } else {
      dispatch({ type: GET_STATS_DETAILS__FAILED });
    }

    dispatch({ type: IS_LOADING_GET_STATS_DETAILS, payload: false });
  } catch (err) {
    dispatch({
      type: GET_STATS_DETAILS__FAILED,
    });
    dispatch({ type: IS_LOADING_GET_STATS_DETAILS, payload: false });
    console.error("Error fetching stock details:", err);
  }
};
