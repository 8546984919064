import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";

// Redux
import { Provider } from "react-redux";
import store from "./store";

import MainContainer from "./MainContainer";
import Layout from "./modules/layout/components";
import Dashboard from "./modules/dashboard/components";
import Alert from "./common/components/Alert";
import Landing from "./modules/auth/components";
import Stocks from "./modules/stocks/components";
import Promotions from "./modules/promotions/components";
import Suppliers from "./modules/suppliers/components";
import Stats from "./modules/stats/components";
import Invoices from "./modules/invoices/components";
import AddNewInvoice from "./modules/invoices/components/AddNewInvoice";

const App = () => {
  return (
    <Provider store={store}>
      <Alert />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainContainer />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="/inventory">
                <Route path="/inventory/stocks" element={<Stocks />} />
                <Route path="/inventory/suppliers" element={<Suppliers />} />
                <Route path="/inventory/invoices">
                  <Route path="/inventory/invoices" element={<Invoices />} />
                  <Route
                    path="/inventory/invoices/new-invoice"
                    element={<AddNewInvoice />}
                  />
                </Route>
                <Route path="/inventory/stats" element={<Stats />} />
              </Route>
              <Route path="/promotions" element={<Promotions />} />
              <Route path="/suppliers" element={<Suppliers />} />

              <Route path="/settings" element={<div>Settings</div>} />
            </Route>
            <Route path="/login" element={<Landing />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
